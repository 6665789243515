import BigNumber from "bignumber.js";
import React, { Fragment, useEffect, useState } from "react";
import { useEthers } from "@usedapp/core";
import { StakedInfo, UnStakedInfo } from "../../../config/types";
import {
  useApeContract,
  useStakingContract,
} from "../../../hooks/useContracts";
import {
  claim,
  setApprovalForAll,
  stake,
  unstake,
} from "../../../utils/callHelper";
import { getStakingAddress } from "../../../utils/addressHelper";
import useRefresh from "../../../utils/useRefresh";
import { getFullDisplayBalance } from "../../../utils/formatBalance";

const initUnStakedInfo: UnStakedInfo = {
  balance: new BigNumber(0),
  tokenIds: [],
  metadatas: [],
};

const initStakedInfo: StakedInfo = {
  balance: new BigNumber(0),
  tokenIds: [],
  metadatas: [],
  rewards: [],
};

const space = <Fragment>&nbsp;&nbsp;</Fragment>;

const Auctions = () => {
  const { account } = useEthers();
  const [requestedApproval, setRequestedApproval] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const apeContract = useApeContract();
  const stakingContract = useStakingContract();
  const stakingAddress = getStakingAddress();

  const { slowRefresh } = useRefresh();

  const [fetchFlag, setFetchFlag] = useState(true);
  const [redraw, setRedraw] = useState(false);

  const [unstakedinfo, setUnStakedInfo] = useState<UnStakedInfo>();
  const [stakedinfo, setStakedInfo] = useState<StakedInfo>();

  const [reward, setReward] = useState(new BigNumber(0));

  const [selectedUnStakedTokenIds, setSelectedUnStakedTokenIds] = useState<
    BigNumber[]
  >([]);
  const [selectedStakedTokenIds, setSelectedStakedTokenIds] = useState<
    BigNumber[]
  >([]);

  const fetchReward = async () => {
    const result = await stakingContract.methods
      .pendingTotalReward(account)
      .call();

    setReward(getFullDisplayBalance(new BigNumber(result)));
  };

  const fetchIsApprovedForAll = async () => {
    const isApp = await apeContract.methods
      .isApprovedForAll(account, stakingAddress)
      .call();
    setIsApproved(isApp);
  };


    const fetchStakingInfo = async () => {

        const apeBalance = await apeContract.methods.balanceOf(account).call();
        const stakeBalance = await stakingContract.methods.stakingAmount(account).call();

        var unstakedInfo: UnStakedInfo = initUnStakedInfo;
        unstakedInfo.tokenIds = [];
        unstakedInfo.metadatas = [];

         var stakedInfo: StakedInfo = initStakedInfo;
         stakedInfo.tokenIds = [];
         stakedInfo.metadatas = [];
         stakedInfo.rewards = [];

      for (let a = 0; a < stakeBalance; a++) {
        const tokenInfo = await stakingContract.methods
          .userInfo(account, a)
          .call();

        let metadata = await apeContract.methods
          .tokenURI(tokenInfo.tokenId)
          .call();

        var stakeTime = ((new Date().getTime() / 1000) - tokenInfo.checkPoint) / 86400;
        let reward = stakeTime * 100 * 1000000000;
        stakedInfo.rewards.push(Number(reward));

        //metadata = metadata.slice(8, metadata.length);
        metadata = "/punks/json/" + tokenInfo.tokenId + ".json";
        const image = "/punks/images/" + tokenInfo.tokenId + ".png";

        stakedInfo.tokenIds.push(tokenInfo.tokenId);
        stakedInfo.metadatas.push(image);

        var staked: StakedInfo = initStakedInfo;
        staked.tokenIds = stakedInfo.tokenIds.slice();
        staked.metadatas = stakedInfo.metadatas.slice();
        setStakedInfo(staked);
        setSelectedUnStakedTokenIds([]);
        setSelectedStakedTokenIds([]);

        var rewards = 0;
        for (let i = 0; i < stakedInfo.rewards.length; i++) {
           rewards += stakedInfo.rewards[i];
        }
        console.log(rewards)
        console.log(new BigNumber(rewards));
        setReward(getFullDisplayBalance(new BigNumber(rewards)));
      }

      for (let a1 = 0; a1 < apeBalance; a1++) {
        const tokenId = await apeContract.methods
          .tokenOfOwnerByIndex(account, a1)
          .call();
        let metadata = await apeContract.methods.tokenURI(tokenId).call();
        //metadata = metadata.slice(8, metadata.length);
        //metadata = "/punks/" + metadata;

        metadata = "/punks/json/" + tokenId + ".json";
        const image = "/punks/images/" + tokenId + ".png";

        unstakedInfo.tokenIds.push(tokenId);
        unstakedInfo.metadatas.push(image);

        var unstaked: StakedInfo = initUnStakedInfo;
        unstaked.tokenIds = unstakedInfo.tokenIds.slice();
        unstaked.metadatas = unstakedInfo.metadatas.slice();
        setUnStakedInfo(unstaked);
        setSelectedUnStakedTokenIds([]);
        setSelectedStakedTokenIds([]);
      }

    };

  useEffect(() => {
    if (fetchFlag && account && stakingContract) {
      fetchIsApprovedForAll();
      fetchStakingInfo();
      setFetchFlag(false);
    }
  }, [
    account,
    stakingContract,
    fetchFlag,
    slowRefresh,
    fetchIsApprovedForAll,
    fetchStakingInfo,
    setFetchFlag,
  ]);

  const IsSelected = (type: any, tokenId: any) => {
    var a = 0;
    const list = type === 0 ? selectedUnStakedTokenIds : selectedStakedTokenIds;
    for (a = 0; a < list.length; a++) {
      if (list[a] === tokenId) {
        return true;
      }
    }
    return false;
  };

  const removeItemFromArray = (
    oldlist: BigNumber[],
    tokenId: any
  ): BigNumber[] => {
    var list: BigNumber[] = oldlist;
    var i = 0;
    for (i = 0; i < list.length; i++) {
      if (list[i] === tokenId) {
        list[i] = list[list.length - 1];
        list.pop();
        break;
      }
    }
    return list;
  };

  const unstakedImageClick = async (tokenId: BigNumber, index: any) => {
    if (await IsSelected(0, tokenId)) {
      let newlist: BigNumber[] = await removeItemFromArray(
        selectedUnStakedTokenIds,
        tokenId
      );
      setSelectedUnStakedTokenIds(newlist);
    } else {
      var newlist1: BigNumber[] = selectedUnStakedTokenIds;
      newlist1.push(tokenId);
      setSelectedUnStakedTokenIds(newlist1);
    }
    setRedraw(!redraw);
  };

  const stakedImageClick = async (tokenId: BigNumber, index: any) => {
    if (await IsSelected(1, tokenId)) {
      const newlist: BigNumber[] = await removeItemFromArray(
        selectedStakedTokenIds,
        tokenId
      );
      setSelectedStakedTokenIds(newlist);
    } else {
      var newlist1: BigNumber[] = selectedStakedTokenIds;
      newlist1.push(tokenId);
      setSelectedStakedTokenIds(newlist1);
    }
    setRedraw(!redraw);
  };

  const handleStake = async () => {
    if (!isApproved) {
      try {
        setRequestedApproval(true);
        await setApprovalForAll(apeContract, stakingContract, account, true);
        setIsApproved(true);
        setRequestedApproval(false);
      } catch {
        console.log("Approve failed");
        setRequestedApproval(false);
      }
    } else {
      try {
        setRequestedApproval(true);
        await stake(stakingContract, selectedUnStakedTokenIds, account);
        setRequestedApproval(false);
        setFetchFlag(true);
      } catch {
        console.log("Stake failed");
        setRequestedApproval(false);
      }
    }
  };

  const handleClaim = async () => {
    try {
      setRequestedApproval(true);
      await claim(stakingContract, account);
      setIsApproved(true);
      setRequestedApproval(false);
    } catch {
      console.log("Claim failed");
      setRequestedApproval(false);
    }
  };

  const handleUnStake = async () => {
    try {
      setRequestedApproval(true);
      await unstake(stakingContract, selectedStakedTokenIds, account);
      setIsApproved(true);
      setRequestedApproval(false);
      setFetchFlag(true);
    } catch {
      console.log("UnStake failed");
      setRequestedApproval(false);
    }
  };

  async function getImageHash(hashVal: any) {
    try {
      let response = await fetch(hashVal);
      let responseJson = await response.json();
      return responseJson.image;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <section style={{ padding: "0px", marginTop: "0px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div className="pad">
          <div className="row text-center">
            <h3 className="em-wide" style={{ fontFamily: "" }}>
              UNSTAKED
            </h3>
          </div>
          <div className="radiuspanel">
            <div className="scrollprop">
              <div className="card-caption col-12 p-0">
                <div className="row justify-content-center" style={{}}>
                  {unstakedinfo &&
                    unstakedinfo.tokenIds &&
                    unstakedinfo.tokenIds.map((tokenId: any, idx: any) => {
                      //let image = unstakedinfo.metadatas[idx];
                      //image = image.slice(8, image.length);
                      let image = "/punks/images/" + tokenId + ".png";
                      const isSelected = IsSelected(0, tokenId);
                      return (
                        <div
                          className="col-5 col-md-5 col-lg-5 col-xl-2 item"
                          style={{
                            marginLeft: 1,
                            marginRight: 1,
                            marginTop: 30,
                          }}
                          onClick={() => unstakedImageClick(tokenId, idx)}
                        >
                          <img
                            className={isSelected ? "withBorder" : "noBorder"}
                            src={image}
                            alt=""
                            style={{ width: "100%" }}
                          />
                          <div style={{ color: "white", textAlign: "center" }}>
                            {tokenId}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="text-center">
              <p className="description">
                SELECT NFTS TO STAKE.
              </p>
            </div>
          </div>
          <div className="row bottomButton">
            <button
              className="btn mt-4 em-wide"
              disabled={requestedApproval}
              style={{ fontFamily: "" }}
              onClick={handleStake}
            >
              {isApproved ? "STAKE" : "APPROVE"}
            </button>
          </div>
        </div>
        <div className="pad">
          <div className="row text-center">
            <h3 className="em-wide" style={{ fontFamily: "" }}>
              STAKED
            </h3>
          </div>
          <div
            className="radiuspanel"
            style={{
              flex: 1,
              minHeight: 400,
              width: "100%",
            }}
          >
            <div className="scrollprop">
              <div className="card-caption col-12 p-0">
                <div
                  className="row justify-content-center"
                  style={{ padding: 10 }}
                >
                  {stakedinfo &&
                    stakedinfo.tokenIds &&
                    stakedinfo.tokenIds.map((tokenId: any, idx: any) => {
                      //let image = stakedinfo.metadatas[idx];
                      //image = image.slice(8, image.length);
                      let image = "/punks/images/" + tokenId + ".png";
                      const isSelected = IsSelected(1, tokenId);
                      return (
                        <div
                          className="col-5 col-md-5 col-lg-5 col-xl-2 item"
                          style={{
                            marginLeft: 1,
                            marginRight: 1,
                            marginTop: 30,
                          }}
                          onClick={() => stakedImageClick(tokenId, idx)}
                        >
                          <img
                            className={isSelected ? "withBorder" : "noBorder"}
                            src={image}
                            alt=""
                            style={{ width: "100%" }}
                          />
                          <div style={{ color: "white", textAlign: "center" }}>
                            {tokenId}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="text-center">
              <p className="description">
                SELECT NFTs TO UN-STAKE.
              </p>
            </div>
          </div>
          <div
            className="row bottomButton"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              className="btn mt-4 em-wide claimbtn"
              disabled={requestedApproval}
              onClick={handleClaim}
            >
              {space}CLAIM{space}
            </button>
            <button
              className="btn mt-4 em-wide"
              disabled={requestedApproval}
              style={{ fontFamily: "" }}
              onClick={handleUnStake}
            >
              UNSTAKE
            </button>
          </div>
        </div>
      </div>
      <div
        className="text-center row col-12"
        style={{ color: "white", marginTop: 0 }}
      >
        <h5
          className="em-wide"
          style={{
            textAlign: "center",
            width: "100%",
            fontFamily: "",
          }}
        >
          $APISH REWARDS
          <br />
          {reward.toString()}
        </h5>
      </div>
    </section>
  );
};

export default Auctions;
