import { InjectedConnector } from "@web3-react/injected-connector";

const supportedChainIds: number[] = [];

if ("40") {
  supportedChainIds.push(Number("40"));
}

export const injected = new InjectedConnector({
  supportedChainIds,
});
