const Address = {
  staking: {
    40: "0x54Bd4f7C2d606797f375272587c36DA2d249Ea6D",
  },
  ape: {
    40: "0x63F8948c2Ee0670758A4E60EED80aAa6Cf093356",
  },
  variable: {
    40: "0x11FBfdf906d32753fA2A083dbd4Fb25C1094C6c4",
  },
};
export default Address;
