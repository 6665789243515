import { Button, Box, Text } from "@chakra-ui/react";
import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import Identicon from "./Identicon";

import { useWeb3React } from "@web3-react/core";
import { injected } from "../utils/connectors";

type Props = {
  handleOpenModal: any;
};

export default function ConnectButton({ handleOpenModal }: Props) {
  const { activateBrowserWallet, account } = useEthers();
  const etherBalance = useEtherBalance(account);

  // function handleConnectWallet() {
  //   activateBrowserWallet();
  // }
  const { activate, setError, chainId, error } = useWeb3React();
  async function handleConnectWallet() {
    try {
      // if (!chainId || chainId.toString() !== "40") {
      //   console.log("Here Change the network to 40");
      //   window.confirm("CSR");
      // }
      await activate(injected);
    } catch (error) {
      if (error instanceof Error) setError(error);
    }
  }

  return account ? (
    <Box
      display="flex"
      alignItems="center"
      background="white"
      borderRadius="xl"
      py="0"
      sx={{ maxWidth: '262px' }}
    >
      <Box px="3">
        <Text color="black" fontSize="md">
          {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)}{" "}
          $TLOS
        </Text>
      </Box>
      <Button
        onClick={handleOpenModal}
        bg="gray.800"
        border="1px solid transparent"
        _hover={{
          border: "1px",
          borderStyle: "solid",
          borderColor: "blue.400",
          backgroundColor: "gray.700",
        }}
        borderRadius="xl"
        m="1px"
        px={3}
        height="38px"
      >
        <Text color="white" fontSize="md" fontWeight="medium" mr="2">
          {account &&
            `${account.slice(0, 6)}...${account.slice(
              account.length - 4,
              account.length
            )}`}
        </Text>
        <Identicon />
      </Button>
    </Box>
  ) : (
    <Button
      onClick={handleConnectWallet}
      // bg="blue.800"
      color="black"
      fontSize="lg"
      fontWeight="medium"
      borderRadius="xl"
      //border="1px solid transparent"
      _hover={
        {
          // borderColor: "blue.700",
          //color: "blue.400",
        }
      }
      _active={
        {
          // backgroundColor: "whiteAlpha.100",
          //borderColor: "blue.700",
        }
      }
    >
      Connect to a wallet
    </Button>
  );
}
