import React from "react";
import { useDisclosure } from "@chakra-ui/react";
import ConnectButton from "../ConnectButton";
import AccountModal from "../AccountModal";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <header id="header container">
      <nav data-aos="zoom-out" data-aos-delay={800} className="">
        <span className="" style={{
                                     display: "flex",
                                     justifyContent: "right",
                                     alignItems: "right",
                                     flexWrap: "wrap",
                                   }}>
                    <ConnectButton handleOpenModal={onOpen} />
                    <AccountModal isOpen={isOpen} onClose={onClose} />
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <a href="#" style={{ height: "100px" }}>
            <div
              className="brand-logo"
              style={{ height: "100%" }}
              // src="img/favicon_desktop.png"
              // alt="sticky brand-logo"
            />
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
